import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navigation/Navbar';
import VideoHero from './components/Hero/VideoHero';
import AuthorBioSection from './components/Sections/AuthorBioSection';
import BookPreviewSection from './components/Sections/BookPreviewSection';
import ImageZoomModal from './components/ImageZoom/ImageZoomModal';
import ConcertCard from './components/Sections/ConcertCard';
import ContactFooter from './components/Footer/ContactFooter';
import AccessibilityWidget from './components/AccessibilityWidget';
import { useScroll } from './hooks/useScroll';
import { BOOK_IMAGES } from './constants/bookData';

export default function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  
  const scrollPosition = useScroll();

  return (
    <div className="min-h-screen bg-black text-white" dir="rtl">
       <Helmet>
        <title>Roy Kornblum</title>
        <link rel="icon" href="/images/logo-black.png" />
      </Helmet>
      <AccessibilityWidget />
      <Navbar />
      
      <VideoHero
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        isMuted={isMuted}
        setIsMuted={setIsMuted}
        scrollPosition={scrollPosition}
      />

      <AuthorBioSection />
      <div id="book">
      <BookPreviewSection
        onImageSelect={setSelectedImage}
        bookImages={BOOK_IMAGES}
      />

      <ImageZoomModal 
        image={selectedImage}
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
      />
      </div>

      <div id="concerts">
      <ConcertCard />
      </div>
      <div id="contact">
        <ContactFooter />
      </div>


    </div>
  );
}