import React from 'react';
import { ArrowLeft } from 'lucide-react';

const SoldOutBadge = () => (
  <div className="absolute -rotate-12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none z-10">
    <div className="relative">
      {/* Outer ring with gradient */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-purple-300/20 to-purple-500/20 rounded-full blur-sm" />
      
      {/* Inner circle with glass effect */}
      <div className="relative px-8 py-2 bg-black/80 backdrop-blur-sm border border-white/10 rounded-full">
        <span className="text-lg font-medium text-white/90">אזלו הכרטיסים</span>
      </div>
    </div>
  </div>
);

const ConcertCard = ({ date, time, venue, city, ticketLink, isSoldOut }) => (
  <div className="relative">
    {/* Main Card */}
    <div className={`relative p-8 rounded-2xl border ${
      isSoldOut 
        ? 'bg-black/40 border-white/5' 
        : 'bg-black/20 border-white/10 hover:border-purple-500/30'
    } transition-all duration-300`}>
      
      {/* Sold Out Overlay */}
      {isSoldOut && (
        <>
          <div className="absolute inset-0 bg-black/40 rounded-2xl" />
          <SoldOutBadge />
        </>
      )}
      
      {/* Content */}
      <div className="relative">
        {/* Date */}
        <div className="mb-6">
          <div className={`text-2xl font-bold mb-1 ${isSoldOut ? 'text-gray-400' : 'text-white'}`}>
            {date}
          </div>
          <div className="text-gray-400">
            {time}
          </div>
        </div>

        {/* Venue */}
        <div className="mb-8">
          <div className={`text-xl mb-1 ${isSoldOut ? 'text-gray-400' : 'text-white'}`}>
            {venue}
          </div>
          <div className="text-gray-400">
            {city}
          </div>
        </div>

        {/* Action Button */}
        <a
          href={ticketLink}
          className={`flex items-center justify-between w-full px-6 py-3 rounded-full transition-all duration-300 ${
            isSoldOut 
              ? 'bg-white/5 text-gray-400 cursor-not-allowed pointer-events-none' 
              : 'bg-purple-600 hover:bg-purple-500'
          }`}
        >
          <span>{isSoldOut ? 'אזלו הכרטיסים' : 'הזמן כרטיסים'}</span>
          {!isSoldOut && <ArrowLeft className="w-4 h-4" />}
        </a>
      </div>
    </div>
  </div>
);

const ConcertsSection = () => {
  const concerts = [
    // {
    //   date: "4.12",
    //   time: "21:00",
    //   venue: "רידינג 3",
    //   city: "תל אביב",
    //   ticketLink: "#",
    //   isSoldOut: true
    // },
    // {
    //   date: "10.12",
    //   time: "21:00",
    //   venue: "רידינג 3",
    //   city: "תל אביב",
    //   ticketLink: "#",
    //   isSoldOut: true
    // },
    // {
    //   date: "21.12",
    //   time: "21:00",
    //   venue: "רידינג 3",
    //   city: "תל אביב",
    //   ticketLink: "#",
    //   isSoldOut: false
    // },
    // {
    //   date: "22.12",
    //   time: "21:00",
    //   venue: "רידינג 3",
    //   city: "תל אביב",
    //   ticketLink: "#",
    //   isSoldOut: false
    // }
  ];

  // Layout classes based on number of concerts
  const getGridClasses = (concertCount) => {
    switch(concertCount) {
      case 1:
        return "max-w-xl mx-auto"; // Single centered card
      case 2:
        return "grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto"; // Two cards
      default:
        return "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"; // Three or more cards
    }
  };

  return (
    <section className="relative py-32 bg-gradient-to-b from-black to-purple-900/20" dir="rtl">
      <div className="absolute inset-0 bg-gradient-to-b" />
      
      <div className="relative max-w-7xl mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold">הופעות קרובות</h2>
        </div>

        {concerts.length > 0 ? (
          <div className={getGridClasses(concerts.length)}>
            {concerts.map((concert, index) => (
              <ConcertCard key={index} {...concert} />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-400 py-12">
            אין הופעות קרובות כרגע
            <br />
            עקבו אחרינו ברשתות החברתיות לעדכונים
          </div>
        )}
      </div>
    </section>
  );
};

export default ConcertsSection;