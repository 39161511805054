import React from 'react';
import { ZoomIn } from 'lucide-react';
import Button from '../Button/Button';

export default function BookPreviewSection({ onImageSelect, bookImages }) {
  return (
    <section className="relative py-32 bg-gradient-to-b from-purple-900/20 to-black" dir="rtl">
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col md:flex-row items-center justify-center gap-12 max-w-5xl mx-auto">
          <div className="relative group w-full md:w-1/2">
            <div 
              className="relative transform transition-transform duration-500 hover:scale-105 cursor-pointer"
              onClick={() => onImageSelect(bookImages[1])}
            >
              <img 
                src={bookImages[1].src}
                alt={bookImages[1].alt}
                className="w-full h-auto shadow-2xl rounded"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-opacity duration-300 rounded flex items-center justify-center">
                <ZoomIn className="w-12 h-12 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 space-y-6">
            <h3 className="text-3xl font-bold">למה זה לא מרגיש כמו בית?</h3>
            <p className="text-xl text-gray-300 leading-relaxed">
              הוא ספר חצוף,
                  פתייני, וסוער המטיח בקוראים מציאות כאוטית ושסועה ומתאר חיפוש אחר תחושת
                  שייכות שתאפשר לשרוד בה.
            </p>
            <Button className="w-full md:w-auto bg-purple-600 text-white px-8 py-4 rounded-full hover:bg-purple-700 transition-colors duration-300 text-lg font-medium">
            להזמנת הספר עכשיו
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}