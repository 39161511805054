import React, { useState } from 'react';
import { Phone, Music, Headphones, Send } from 'lucide-react';

const WhatsAppJoinSection = () => {
  const [phone, setPhone] = useState('');
  
  return (
    <section className="relative py-32 bg-gradient-to-b from-purple-900/20 to-black" dir="rtl">
      <div className="max-w-7xl mx-auto px-6">
        <div className="mb-16 text-right">
            <h2 className="text-4xl font-bold bg-gradient-to-r from-white to-purple-400 bg-clip-text text-transparent">
                הצטרפו לקהילת המעריצים הפרטית
              </h2>
          <p className="text-lg text-gray-300">
            היו הראשונים לשמוע על השירים החדשים, ההופעות הקרובות והתכנים הבלעדיים
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Left Column - Phone Input */}
          <div className="grid gap-4">
            <div className="bg-[#0E0B14] p-6 rounded-2xl flex items-center gap-4">
              <Music className="w-6 h-6 text-purple-400" />
              <div>
                <h3 className="text-white font-medium">תוכן מוזיקלי בלעדי</h3>
                <p className="text-gray-400 text-sm">גישה לדמואים, הקלטות אקוסטיות מיוחדות וחומרים שטרם שוחררו</p>
              </div>
            </div>

            <div className="bg-[#0E0B14] p-6 rounded-2xl flex items-center gap-4">
              <Headphones className="w-6 h-6 text-purple-400" />
              <div>
                <h3 className="text-white font-medium">עדכונים בזמן אמת</h3>
                <p className="text-gray-400 text-sm">קבלו עדכונים על הופעות חדשות והזדמנות לרכוש כרטיסים לפני כולם</p>
              </div>
            </div>

            <div className="bg-[#0E0B14] p-6 rounded-2xl flex items-center gap-4">
              <Send className="w-6 h-6 text-purple-400" />
              <div>
                <h3 className="text-white font-medium">קהילה אינטימית</h3>
                <p className="text-gray-400 text-sm">הזדמנות לשוחח עם מעריצים נוספים ולקבל עדכונים אישיים</p>
              </div>
            </div>
          </div>

          {/* Right Column - Benefits */}
          <div className="bg-[#0E0B14] rounded-3xl p-8">
            <div className="mb-2 text-sm text-gray-400">מספר טלפון לוואטסאפ</div>
            <div className="space-y-6">
              <div className="relative">
                <div className="flex items-center">
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="הזינו את המספר שלכם"
                    className="w-full bg-black/40 border border-gray-800 rounded-xl px-6 py-3
                             focus:outline-none focus:ring-2 focus:ring-purple-500/40 focus:border-transparent
                             placeholder:text-gray-600 text-right"
                  />
                  <Phone className="absolute left-4 h-5 w-5 text-gray-500" />
                </div>
              </div>

              <div className="text-xs text-gray-500">
                המספר ישמש אך ורק לצורך הצטרפות לקבוצת הוואטסאפ ולא יועבר לגורמים אחרים
              </div>

              <button
                type="button"
                className="w-full bg-purple-600 hover:bg-purple-500 text-white px-6 py-3 rounded-xl
                          transition-all duration-300"
              >
                הצטרפו לקהילה שלנו
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatsAppJoinSection;