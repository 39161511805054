import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const AccessibilityIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 100 100"
    fill="currentColor"
    aria-hidden="true"
    role="img"
  >
    <circle cx="50" cy="50" r="45" fill="none" stroke="currentColor" strokeWidth="8"/>
    <path d="M50 18 C 44 18, 39 23, 39 29 C 39 35, 44 40, 50 40 C 56 40, 61 35, 61 29 C 61 23, 56 18, 50 18 M32 45 L68 45 L68 52 L58 52 L58 82 L42 82 L42 52 L32 52Z"/>
  </svg>
);

const AccessibilityWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [settings, setSettings] = useState({
    fontSize: 1,
    contrast: 1,
  });

  useEffect(() => {
    const savedSettings = localStorage.getItem('accessibility-settings');
    if (savedSettings) {
      setSettings(JSON.parse(savedSettings));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('accessibility-settings', JSON.stringify(settings));
    applySettings(settings);
  }, [settings]);

  const applySettings = (currentSettings) => {
    document.documentElement.style.setProperty('--font-size-multiplier', currentSettings.fontSize);
    document.documentElement.style.setProperty('--contrast-multiplier', currentSettings.contrast);
  };

  return (
    <>
      {/* Floating Button */}
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 z-50 p-3 bg-purple-600 text-white rounded-full shadow-lg hover:bg-purple-700 transition-colors duration-200"
        aria-label="פתח תפריט נגישות"
      >
        <AccessibilityIcon />
      </button>

      {/* Overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 transition-opacity duration-200"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Accessibility Panel */}
      <div
        className={`fixed inset-y-0 right-0 w-80 max-w-full bg-gray-900 text-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        dir="rtl"
      >
        <div className="h-full flex flex-col p-6">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-xl font-medium">הגדרות נגישות</h2>
            <button
              onClick={() => setIsOpen(false)}
              className="p-2 hover:bg-gray-800 rounded-full transition-colors duration-200"
              aria-label="סגור תפריט נגישות"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-8">
            {/* Font Size Control */}
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-300">
                גודל טקסט
              </label>
              <input
                type="range"
                min="0.8"
                max="1.5"
                step="0.1"
                value={settings.fontSize}
                onChange={(e) => setSettings(prev => ({
                  ...prev,
                  fontSize: parseFloat(e.target.value)
                }))}
                className="w-full bg-gray-700 rounded-lg appearance-none cursor-pointer accent-purple-500"
              />
              <div className="flex justify-between text-sm text-gray-400">
                <span>A</span>
                <span className="text-lg">A</span>
              </div>
            </div>

            {/* Contrast Control */}
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-300">
                ניגודיות
              </label>
              <input
                type="range"
                min="1"
                max="1.5"
                step="0.1"
                value={settings.contrast}
                onChange={(e) => setSettings(prev => ({
                  ...prev,
                  contrast: parseFloat(e.target.value)
                }))}
                className="w-full bg-gray-700 rounded-lg appearance-none cursor-pointer accent-purple-500"
              />
              <div className="flex justify-between text-sm text-gray-400">
                <span>רגיל</span>
                <span>גבוה</span>
              </div>
            </div>

            {/* Reset Button */}
            <button
              onClick={() => setSettings({ fontSize: 1, contrast: 1 })}
              className="w-full py-3 px-4 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors duration-200 mt-4"
            >
              איפוס הגדרות
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessibilityWidget;