export const BOOK_IMAGES = [
    {
      src: '/images/book-front.png',
      alt: 'ספר "למה זה לא מרגיש כמו בית?" - כריכה קדמית',
      title: 'כריכה קדמית'
    },
    {
      src: '/images/book-with-back.png',
      alt: 'ספר "למה זה לא מרגיש כמו בית?" - כריכה אחורית',
      title: 'כריכה אחורית'
    }
  ];
  
  export const EDITIONS = [
    {
      name: 'דיגיטלי',
      price: '₪99',
      features: [
        'עותק דיגיטלי של הספר',
        'תוכן פרשנות בלעדי',
        'תוכן מאחורי הקלעים',
        'חוברת דיגיטלית'
      ]
    },
    // ... other editions
  ];