import React from 'react';

export default function AuthorBioSection() {
  return (
    <section className="relative py-16 md:py-32 bg-gradient-to-b from-black to-purple-900/20" dir="rtl">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 items-center">
          {/* Image Container */}
          <div className="relative md:order-1">
            <div className="aspect-square overflow-hidden rounded-2xl shadow-xl">
              <img 
                src="/images/roy_picture.png" 
                alt="רועי קורנבלום" 
                className="w-full h-full object-cover object-center transform hover:scale-105 transition-transform duration-300"
                loading="lazy"
              />
            </div>
            {/* Purple background effect */}
            <div 
              className="absolute -z-10 top-4 right-4 w-full h-full bg-purple-500/10 rounded-2xl 
                         transition-transform duration-300 group-hover:translate-x-1 group-hover:translate-y-1"
            />
          </div>

          {/* Text Content */}
          <div className="space-y-6 md:space-y-8 md:order-2">
            <div className="prose prose-lg prose-invert max-w-none">
              <p className="text-lg md:text-xl leading-relaxed text-gray-200">
                רועי קורנבלום, הוא כותב, מוזיקאי ויוצר.
              </p>
              <p className="text-lg md:text-xl leading-relaxed text-gray-200">
                הסרטונים שבהם הוא מבצע את הטקסטים שכתב 
                <span className="hidden md:inline"><br /></span>{' '}
                זכו למיליוני צפיות ברשתות החברתיות.
                <span className="hidden md:inline"><br /></span>{' '}
                ספרו הראשון,{' '}
                <span className="font-bold text-white">
                  למה זה לא מרגיש כמו בית?
                </span>
                <span className="hidden md:inline"><br /></span>{' '}
                מאגד את הטקסטים הפופולריים שלו מהרשתות החברתיות 
                <span className="hidden md:inline"><br /></span>{' '}
                וכן רבים נוספים שרואים אור לראשונה.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}