import React, { useState, useRef, useEffect } from 'react';
import { Volume2, VolumeX, SkipForward } from 'lucide-react';

const VIDEO_SEQUENCE = [
    'lqg7Yu65eoc',
    'Y9NfIQ-FkUM',
    'e_sHrTO10GU',
    'jLedtjtOZnQ',
    '_ZHKto2JzC4'
];

export const VideoHero = ({
    isMuted,
    setIsMuted,
    scrollPosition
}) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(() => {
        return Math.floor(Math.random() * VIDEO_SEQUENCE.length);
    });
    const [isTransitioning, setIsTransitioning] = useState(false);
    const playerRef = useRef(null);
    const initialVideoId = useRef(VIDEO_SEQUENCE[currentVideoIndex]);
    const [isMobile, setIsMobile] = useState(false);

    // Check for mobile device
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        checkMobile();
        window.addEventListener('resize', checkMobile);
        
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: initialVideoId.current,
                playerVars: {
                    autoplay: 1,
                    controls: 0,
                    showinfo: 0,
                    rel: 0,
                    loop: 1,
                    playlist: initialVideoId.current,
                    playsinline: 1,
                    enablejsapi: 1,
                    mute: 1
                },
                events: {
                    onReady: (event) => {
                        event.target.playVideo();
                    }
                }
            });
        };

        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, []);

    const handleMuteToggle = () => {
        if (playerRef.current) {
            if (isMuted) {
                playerRef.current.unMute();
            } else {
                playerRef.current.mute();
            }
            setIsMuted(!isMuted);
        }
    };

    const cycleVideo = () => {
        setIsTransitioning(true);
        
        setTimeout(() => {
            const nextIndex = currentVideoIndex === VIDEO_SEQUENCE.length - 1 
                ? 0
                : currentVideoIndex + 1;
            
            setCurrentVideoIndex(nextIndex);
            
            if (playerRef.current) {
                playerRef.current.loadVideoById({
                    videoId: VIDEO_SEQUENCE[nextIndex],
                    startSeconds: 0
                });
            }
            
            setTimeout(() => {
                setIsTransitioning(false);
            }, 500);
        }, 500);
    };

    // Calculate video scale based on device
    const getVideoScale = () => {
        if (isMobile) {
            // Increased scale for better mobile coverage
            return window.innerWidth < 480 ? 2.5 : 2;
        }
        return 1.5;
    };

    return (
        <section className="relative h-screen flex items-center justify-center overflow-hidden">
            {/* Video Background */}
            <div className="absolute inset-0 w-full h-full">
                <div 
                    id="youtube-player"
                    className={`absolute w-full h-full object-cover transition-opacity duration-500 ${
                        isTransitioning ? 'opacity-0' : 'opacity-100'
                    }`}
                    style={{
                        pointerEvents: 'none',
                        width: '100vw',
                        height: '100vh',
                        transform: `scale(${getVideoScale()}) translateY(${scrollPosition * 0.5}px)`
                    }}
                />
                {/* Overlay Gradient - Enhanced for mobile */}
                <div
                    className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/60 to-black"
                    style={{
                        transform: `translateY(${scrollPosition * 0.5}px)`,
                    }}
                />
            </div>

            {/* Content */}
            <div
                className="absolute inset-0 bg-gradient-to-b from-purple-900/50 to-black"
                style={{
                    transform: `translateY(${scrollPosition * 0.5}px)`,
                }}
            />
            <div className="relative z-10 text-center px-4">
                <div className="max-w-2xl mx-auto mb-4 md:mb-8">
                    <img
                        src="/images/logo-white.png"
                        alt="רועי קורנבלום"
                        className="w-full h-auto max-w-[85vw] md:max-w-full mx-auto"
                    />
                </div>
            </div>

            {/* Video Controls - Repositioned for mobile */}
            <div 
                className={`absolute z-10 flex gap-3 md:gap-4 transition-all duration-300 ${
                    isMobile 
                        ? 'bottom-6 left-1/2 transform -translate-x-1/2' 
                        : 'bottom-8 left-8'
                }`}
            >
                <button
                    onClick={handleMuteToggle}
                    className="p-2.5 md:p-3 bg-white/10 backdrop-blur-lg rounded-full hover:bg-white/20 transition-colors"
                    aria-label={isMuted ? "Unmute" : "Mute"}
                >
                    {isMuted ? (
                        <VolumeX className="w-5 h-5 md:w-6 md:h-6 text-white" />
                    ) : (
                        <Volume2 className="w-5 h-5 md:w-6 md:h-6 text-white" />
                    )}
                </button>
                <button
                    onClick={cycleVideo}
                    disabled={isTransitioning}
                    className={`p-2.5 md:p-3 bg-white/10 backdrop-blur-lg rounded-full transition-all duration-300 
                              ${isTransitioning ? 'opacity-50 cursor-not-allowed' : 'hover:bg-white/20'}`}
                    aria-label="Next video"
                >
                    <SkipForward 
                        className={`w-5 h-5 md:w-6 md:h-6 text-white transition-transform duration-300 
                                  ${isTransitioning ? 'rotate-180' : ''}`} 
                    />
                </button>
            </div>
        </section>
    );
};

export default VideoHero;