import React, { useState, useEffect, useCallback } from 'react';
import { X, ZoomIn, ZoomOut } from 'lucide-react';

export default function ImageZoomModal({ image, isOpen, onClose }) {
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleZoomIn = useCallback(() => {
    setScale(prev => Math.min(prev + 0.5, 3));
    setPosition({ x: 0, y: 0 });
  }, []);

  const handleZoomOut = useCallback(() => {
    setScale(prev => Math.max(prev - 0.5, 0.5));
    setPosition({ x: 0, y: 0 });
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (e.button === 0) {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  }, [position]);

  const handleMouseMove = useCallback((e) => {
    if (isDragging && scale > 1) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y
      });
    }
  }, [isDragging, scale, dragStart]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    const zoomSpeed = 0.1;
    const delta = e.deltaY * -0.01;
    const newScale = Math.max(0.5, Math.min(3, scale + (delta * zoomSpeed)));

    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    if (newScale !== scale) {
      const scaleChange = newScale - scale;
      const newPosition = {
        x: position.x - (mouseX - rect.width / 2) * (scaleChange / scale),
        y: position.y - (mouseY - rect.height / 2) * (scaleChange / scale)
      };

      setScale(newScale);
      setPosition(newPosition);
    }
  }, [scale, position]);

  useEffect(() => {
    if (!isOpen) {
      setScale(1);
      setPosition({ x: 0, y: 0 });
    }

    const handleKeyPress = (e) => {
      if (e.key === 'Escape') onClose();
      if (e.key === '+') handleZoomIn();
      if (e.key === '-') handleZoomOut();
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyPress);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose, handleZoomIn, handleZoomOut]);

  if (!isOpen || !image) return null;

  return (
    <div 
      className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center"
      onClick={onClose}
    >
      <div 
        className="relative w-full h-full max-w-7xl mx-auto px-4 py-8"
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 z-50 text-white p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
          aria-label="Close modal"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="absolute top-4 left-4 z-50 flex gap-2">
          <button
            onClick={handleZoomIn}
            className="text-white p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
            aria-label="Zoom in"
          >
            <ZoomIn className="w-6 h-6" />
          </button>
          <button
            onClick={handleZoomOut}
            className="text-white p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
            aria-label="Zoom out"
          >
            <ZoomOut className="w-6 h-6" />
          </button>
        </div>

        <div 
          className="w-full h-full flex items-center justify-center overflow-hidden"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onWheel={handleWheel}
        >
          <img
            src={image.src}
            alt={image.alt}
            className="max-h-[90vh] w-auto transition-transform duration-200"
            style={{
              transform: `scale(${scale}) translate(${position.x/scale}px, ${position.y/scale}px)`,
              cursor: isDragging ? 'grabbing' : 'grab'
            }}
            draggable="false"
          />
        </div>

        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-white bg-black/50 px-3 py-1 rounded-full text-sm">
          {(scale * 100).toFixed(0)}%
        </div>

        <div className="absolute bottom-4 right-4 text-white/70 text-sm">
          <p>גלגל עכבר: זום פנימה/החוצה</p>
          <p>גרור: הזז את התמונה</p>
          <p>+/-: שליטה בזום</p>
        </div>
      </div>
    </div>
  );
}